export const products = [
  {
    id: 1,
    img: "https://i.imgur.com/q1GNLF1.jpg",
    link: "https://omnifood.exyoursize.fit",
  },
  {
    id: 2,
    img: "https://i.imgur.com/XdYuiic.png",
    link: "https://todoreact.exyoursize.fit/",
  },
  {
    id: 3,
    img: "https://i.imgur.com/ehlDWqu.jpg",
    link: "https://todo.exyoursize.fit",
  },
  {
    id: 4,
    img: "https://i.imgur.com/ISHQZQe.jpg",
    link: "https://carousel.exyoursize.fit",
  },
  {
    id: 5,
    img: "https://i.imgur.com/kJ5TZK4.jpg",
    link: "https://estatery.exyoursize.fit",
  },
  {
    id: 6,
    img: "https://hatrabbits.com/wp-content/uploads/2017/01/random.jpg",
    link: "https://www.google.co.in",
  },
];
